<template>
  <svg viewBox="0 0 95 27" fill="none" xmlns="http://www.w3.org/2000/svg" class="translate-y-1">
    <path
      d="M16.1066 18.0589C15.7464 18.6311 15.1504 18.9402 14.5906 19.2441C11.5381 20.9019 8.41294 21.0322 5.27485 19.4996C0.835924 17.3284 -1.17235 11.9797
    0.698584 7.46369C1.17521 6.28935 1.88411 5.22022 2.78398 4.31856C3.68385 3.4169 4.75672 2.70072 5.94011 2.21173C7.1235 1.72275 8.39376 1.47072 9.67692
    1.47034C10.9601 1.46995 12.2305 1.72121 13.4142 2.20949C15.644 3.10133 17.4485 4.79244 18.4647 6.94261C18.5004 6.98394 18.5238 7.03432 18.532
    7.08803C18.5402 7.14175 18.5331 7.19666 18.5113 7.24657L15.1659 7.00902C12.1479 6.78595 9.12987 6.56542 6.11185 6.34746C5.43292 6.29893 5.10642 6.60289
    5.10383 7.25679C5.10383 9.14187 5.10383 11.0295 5.10383 12.9146C5.10383 13.4254 5.28522 13.6247 5.81385 13.7243C6.98512 13.9414 8.14603 14.1509 9.33027
    14.3654C9.55571 14.4855 9.57385 14.7077 9.5894 14.9197C9.71228 15.8351 10.1441 16.6831 10.8153 17.3271C11.4866 17.9711 12.3582 18.3738 13.2898
    18.4702C14.0362 18.5597 14.7931 18.4439 15.4769 18.1356C15.6712 18.0615 15.8785 17.9287 16.1066 18.0589Z"
      fill="#22C55E" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.139 18.672C14.1688 18.7803 15.207 18.5658 16.106 18.0589C16.3978 17.8578 16.6656 17.6247 16.9042 17.3642C19.0187 15.308 18.2257 11.5123 15.4556 10.4267C12.4937 9.26958 9.51631 11.1879 9.32715 14.3782C9.37656 14.8799 9.4702 15.3765 9.60701 15.8622C10.0683 17.2748 11.6516 18.4957 13.139 18.672ZM14.5453 12.5711C14.2995 12.4668 14.0349 12.4125 13.7673 12.4114C13.4998 12.4001 13.2327 12.4417 12.9818 12.5337C12.7309 12.6258 12.5013 12.7664 12.3064 12.9473C12.1115 13.1282 11.9553 13.3458 11.8471 13.5871C11.7388 13.8285 11.6807 14.0888 11.6761 14.3526C11.667 14.8916 11.8743 15.4122 12.2529 15.8012C12.6315 16.1901 13.1506 16.4159 13.6973 16.4293C13.9638 16.4337 14.2285 16.3859 14.4762 16.2888C14.7238 16.1917 14.9495 16.0472 15.1401 15.8636C15.3307 15.68 15.4825 15.4609 15.5867 15.2191C15.6908 14.9773 15.7454 14.7175 15.747 14.4548C15.7582 14.1912 15.7153 13.9282 15.6209 13.6813C15.5266 13.4345 15.3827 13.209 15.1979 13.0182C15.0131 12.8274 14.7911 12.6754 14.5453 12.5711Z"
      fill="#27272A" />

    <g v-if="text == true">
      <path
        d="M64.8494 7.37682C64.8494 9.10864 64.8494 10.8405 64.8494 12.5723C64.8544 13.0976 64.917 13.6209 65.036 14.133C65.4221 15.8316 66.4716 16.6617 68.2337 16.6694C69.9958 16.677 71.0323 15.8418 71.4106 14.1738C71.5622 13.4944 71.637 12.8005 71.6335 12.1048C71.6335 8.73315 71.6335 5.36147 71.6335 1.99235C71.6335 1.59898 71.7164 1.45339 72.1517 1.46105C73.5174 1.48915 74.883 1.48149 76.2512 1.46105C76.5907 1.46105 76.7202 1.54279 76.7176 1.90039C76.7176 5.50196 76.7358 9.10608 76.6969 12.7077C76.6814 14.0461 76.6114 15.3948 76.1139 16.6745C75.8181 17.4862 75.3456 18.2242 74.7296 18.8366C74.1136 19.4491 73.369 19.9213 72.5482 20.2199C69.735 21.3419 66.5859 21.3226 63.787 20.1662C61.5377 19.2518 60.3431 17.474 59.9648 15.1445C59.8344 14.3043 59.7712 13.4554 59.7756 12.6055C59.7756 9.08054 59.7756 5.5556 59.7756 2.03066C59.7756 1.59132 59.8844 1.44572 60.3483 1.45594C61.6439 1.48659 62.9552 1.48659 64.2586 1.45594C64.7198 1.45594 64.8805 1.5479 64.8701 2.03832C64.839 3.82634 64.8572 5.59391 64.8572 7.37171L64.8494 7.37682Z"
        fill="#27272A" />
      <path
        d="M57.0137 16.4318C57.0137 17.6119 57.0137 18.7844 57.0137 19.9568C57.0137 20.1611 56.9178 20.248 56.739 20.3297C55.1558 21.0217 53.3767 21.1451 51.7109 20.6784C50.045 20.2118 48.5976 19.1846 47.6202 17.7754C45.9798 15.3309 46.3115 11.6757 48.2913 9.4918C50.2711 7.30787 53.7046 6.57734 56.3555 7.57352C56.8167 7.74465 57.0577 7.96177 57.0266 8.50584C56.9696 9.4305 57.0007 10.3603 57.0266 11.2875C57.0266 11.6553 56.9619 11.7243 56.6172 11.5123C55.4304 10.7817 54.2047 10.6259 52.9272 11.3258C51.3931 12.1687 50.9422 14.5263 51.9813 15.9235C53.0205 17.3207 55.1168 17.6094 56.7027 16.5493C56.7413 16.5067 56.7896 16.4738 56.8437 16.4533C56.8978 16.4329 56.9561 16.4255 57.0137 16.4318Z"
        fill="#27272A" />
      <path
        d="M44.4767 14.018C44.4767 16.0257 44.4767 18.0359 44.4767 20.0462C44.4767 20.4191 44.3575 20.5187 43.9921 20.5136C42.7379 20.4932 41.482 20.4932 40.2243 20.5136C39.8797 20.5136 39.7631 20.4268 39.7657 20.0743C39.776 16.0385 39.776 12.0035 39.7657 7.96943C39.7657 7.61694 39.8797 7.52243 40.2243 7.52754C41.4656 7.54542 42.7068 7.55053 43.9455 7.52754C44.3549 7.52754 44.4845 7.62205 44.4793 8.0384C44.4637 10.0282 44.4767 12.0231 44.4767 14.018Z"
        fill="#27272A" />
      <path
        d="M42.1439 0C42.6641 0.00210776 43.1719 0.156665 43.6028 0.444016C44.0336 0.731366 44.368 1.13853 44.5634 1.61372C44.7589 2.08891 44.8065 2.61067 44.7003 3.11263C44.5942 3.61459 44.3389 4.07408 43.9671 4.43267C43.5952 4.79126 43.1236 5.03274 42.6122 5.1264C42.1007 5.22006 41.5726 5.16168 41.0949 4.95867C40.6172 4.75566 40.2116 4.4172 39.9296 3.98633C39.6476 3.55547 39.5019 3.05166 39.5112 2.53898C39.5344 1.86321 39.821 1.22227 40.3114 0.749414C40.8017 0.276556 41.458 0.00815281 42.1439 0V0Z"
        fill="#27272A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.3419 19.9696V19.3974C84.4122 19.4334 84.4797 19.4743 84.544 19.52C86.8036 21.6146 90.5377 21.2825 92.6393 19.4051C94.5828 17.6707 95.2876 15.4638 94.8963 12.9452C94.4921 10.3603 93.1135 8.44967 90.5714 7.50202C88.5916 6.76638 85.8448 7.0448 84.5025 8.83281C84.4886 8.85271 84.4662 8.86618 84.4299 8.88806C84.4057 8.90271 84.3751 8.92113 84.3367 8.94776C84.3367 8.82738 84.3333 8.71638 84.3301 8.60892C84.3238 8.40399 84.318 8.21196 84.3367 7.99245C84.3652 7.65783 84.259 7.53523 83.8962 7.53523C82.6394 7.55311 81.3826 7.55055 80.1258 7.53523C79.7656 7.53012 79.6309 7.63229 79.6309 8.00777C79.6412 14.1926 79.6412 20.3783 79.6309 26.5648C79.6309 26.9301 79.7708 26.999 80.1025 26.999C81.31 26.9786 82.5202 26.9684 83.7303 26.999C84.1916 27.0118 84.3419 26.9071 84.3419 26.4166C84.3298 24.9844 84.3338 23.5511 84.3378 22.1181C84.3399 21.4018 84.3419 20.6855 84.3419 19.9696ZM84.1965 14.1815C84.2406 15.8622 85.5518 16.9963 87.2802 16.9325C89.2963 16.8788 90.1954 15.5199 90.1747 13.9618C90.1514 12.1942 88.8169 11.0576 86.9796 11.1317C85.4326 11.193 84.1499 12.2888 84.1965 14.1815Z"
        fill="#27272A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.2766 2.00256V10.9963C23.2766 11.3894 23.2767 11.7826 23.2768 12.1757C23.2777 14.7967 23.2786 17.4179 23.2403 20.0411C23.2377 20.3987 23.3284 20.5162 23.7093 20.5162C25.0749 20.4906 26.4432 20.4855 27.8088 20.5162C28.2519 20.5238 28.327 20.368 28.327 19.9823V14.9273C28.3347 14.5134 28.3184 14.3296 28.4017 14.2495C28.4871 14.1674 28.677 14.1943 29.1044 14.1943C29.4322 14.19 29.76 14.1918 30.0876 14.1936C31.1405 14.1994 32.1915 14.2052 33.235 14.0103C35.3081 13.6246 36.8836 12.6029 37.5755 10.5595C38.1386 8.92274 38.1811 7.1555 37.6973 5.4943C37.5321 4.86364 37.2407 4.27189 36.8402 3.75384C36.4397 3.23579 35.9382 2.8019 35.3651 2.47766C34.2366 1.83333 32.9558 1.49328 31.6517 1.4917C30.2366 1.47645 28.8214 1.4755 27.4062 1.47455C26.2145 1.47375 25.0228 1.47295 23.8311 1.46361C23.4061 1.46105 23.2766 1.56833 23.2766 2.00256ZM31.7021 5.79826C30.9921 5.47387 30.2432 5.47387 29.4865 5.47387C29.4189 5.47387 29.3536 5.47461 29.2888 5.47534C29.1126 5.47733 28.9404 5.47928 28.7376 5.4662C28.4604 5.44832 28.3438 5.54283 28.3438 5.83147V9.86727C28.3438 10.0946 28.4111 10.2019 28.6703 10.2019C29.4704 10.2395 30.2723 10.2002 31.0646 10.0844C31.8135 9.94901 32.4173 9.57353 32.6428 8.80723C32.8402 8.16687 32.8339 7.48224 32.6246 6.84553C32.5585 6.61483 32.4415 6.40135 32.282 6.22033C32.1226 6.03931 31.9246 5.89519 31.7021 5.79826Z"
        fill="#27272A" />
    </g>
  </svg>
</template>

<script setup>
  const props = defineProps({
    text: {
      type: Boolean,
      default: true
    }
  })
</script>
